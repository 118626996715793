import MicroModal from "micromodal";
import { confettiCannon } from "./confetti.js";

document
  .getElementById("contact-form")
  .addEventListener("submit", function (event) {
    event.preventDefault(); // Prevent form submission

    // Validate form inputs
    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const message = document.getElementById("message").value.trim();

    // Email validation using a regular expression
    const emailRegex = /^\S+@\S+\.\S+$/;

    let hasError = false; // Track if there's any error

    if (!name) {
      displayErrorMessage("name", "Name cannot be empty");
      hasError = true;
    } else {
      resetErrorMessage("name");
    }

    if (!email) {
      displayErrorMessage("email", "Email cannot be empty");
      hasError = true;
    } else if (!emailRegex.test(email)) {
      displayErrorMessage("email", "Please enter a valid email address");
      hasError = true;
    } else {
      resetErrorMessage("email");
    }

    if (!message) {
      displayErrorMessage("message", "Message cannot be empty");
      hasError = true;
    } else {
      resetErrorMessage("message");
    }

    if (hasError) {
      return; // Exit if there's any error
    }

    // Construct the form data
    const formData = {
      name: name,
      email: email,
      message: message,
    };

    // If all validations pass, reset any displayed error messages
    resetErrorMessages();

    // Perform form submission
    submitForm(formData);
  });

function displayErrorMessage(inputId, message) {
  const errorElement = document.getElementById(`${inputId}-error`);
  errorElement.textContent = message;
  errorElement.classList.add("active");
  document.getElementById(inputId).focus();
}

function resetErrorMessage(inputId) {
  const errorElement = document.getElementById(`${inputId}-error`);
  errorElement.textContent = "";
  errorElement.classList.remove("active");
}

function resetErrorMessages() {
  const errorMessages = document.getElementsByClassName("error-message");
  for (const errorMessage of errorMessages) {
    console.log(errorMessage);
    errorMessage.textContent = "";
    errorMessage.classList.remove("active");
  }
}

function displayModal(modalId) {
  MicroModal.show(modalId);
}

function submitForm(formData) {
  // Make an API request to the backend (API Gateway) for form submission
  const submitButton = document.getElementById("submit");
  submitButton.disabled = true;
  submitButton.value = "Please wait...";

  // Check if user has enabled reduced motion preference
  const prefersReducedMotion = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  ).matches;

  fetch("https://wvtllmjsrd.execute-api.us-east-1.amazonaws.com/dev/submit", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
    mode: "cors",
  })
    // If response ok, clear form, spray animated confetti on page and display a modal with dynamic confirmation text
    .then(function (response) {
      if (response.ok) {
        document.getElementById("contact-form").reset();
        if (
          !prefersReducedMotion &&
          localStorage.getItem("animation") === "on"
        ) {
          confettiCannon();
        }
        displayModal("modal-1");
      } else {
        // If response is error, display modal with dynamic error text
        throw new Error("Form submission failed.");
      }
    })
    .catch(function (error) {
      console.error(error);
      displayModal("modal-2");
    })
    .finally(function () {
      submitButton.disabled = false;
      submitButton.value = "Send Message";
    });
}

MicroModal.init();
