const sunSvg = document.getElementById("sun-svg");
const moonSvg = document.getElementById("moon-svg");
const svgMask = document.getElementById("mask");

// If toggleTheme button is clicked & theme is 'Day', swap SVGs to 'Night' SVGs & vice versa
swapAnimation = () => {
  let day = document.querySelectorAll("#sun-svg, #day-earth-svg");
  let night = document.querySelectorAll("#moon-svg, #night-earth-svg");

  if (localStorage.getItem("theme") === "light") {
    night.forEach((element) => (element.style.display = "none"));
    day.forEach((element) => (element.style.display = "block"));
  } else if (localStorage.getItem("theme") === "dark") {
    night.forEach((element) => (element.style.display = "block"));
    day.forEach((element) => (element.style.display = "none"));
  }
};
// Set data-theme attribute to light or dark & store choice in local storage
toggleTheme = () => {
  if (localStorage.getItem("theme") == "light") {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  } else {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  }
  swapAnimation();
};

// Add click/keydown (Enter, Spacebar) event listeners to moon & sun SVGs
svgMask.addEventListener("click", toggleTheme);
moonSvg.addEventListener("keydown", function (event) {
  if (
    event.keyCode === 13 ||
    event.keyCode === 32 ||
    event.code === "Enter" ||
    event.code === "Space"
  ) {
    event.preventDefault();
    toggleTheme();
    sunSvg.focus();
  }
});
sunSvg.addEventListener("keydown", function (event) {
  if (
    event.keyCode === 13 ||
    event.keyCode === 32 ||
    event.code === "Enter" ||
    event.code === "Space"
  ) {
    event.preventDefault();
    toggleTheme();
    moonSvg.focus();
  }
});
// Check local storage for stored theme, if none, check browser/system for preferred theme, if none, use light theme
detectColorScheme = () => {
  let theme = "light";
  if (localStorage.getItem("theme") == null) {
    if (window.matchMedia("(prefers-color-scheme: light)").matches) {
      localStorage.setItem("theme", "light");
      theme = "light";
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      localStorage.setItem("theme", "dark");
      theme = "dark";
    }
  }
  if (localStorage.getItem("theme")) {
    if (localStorage.getItem("theme") == "dark") {
      theme = "dark";
    }
  } else if (!window.matchMedia) {
    return false;
  } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    theme = "dark";
  }

  if (theme == "dark") {
    document.documentElement.setAttribute("data-theme", "dark");
  }
  swapAnimation();
};

detectColorScheme();
