const playPauseButton = document.querySelectorAll(".play-pause-button");
const pauseSvg = document.getElementById("pause-svg");
const playSvg = document.getElementById("play-svg");
const planetsAnimation = document.querySelectorAll(
  "#sun-svg, #moon-svg, #day-earth-svg, #night-earth-svg, #earth-orbit"
);

// Check if user has enabled reduced motion preference
const prefersReducedMotion = window.matchMedia(
  "(prefers-reduced-motion: reduce)"
).matches;

// If reduced motion is enabled, store animation off in local storage
if (prefersReducedMotion) {
  localStorage.setItem("animation", "off");
}

// Check local storage for stored animation state, if empty, set to 'on' as default
if (!localStorage.getItem("animation")) {
  localStorage.setItem("animation", "on");
}

// If local storage animation is set to off, pause animations
if (localStorage.getItem("animation") === "off") {
  planetsAnimation.forEach((element) => {
    element.style.animationPlayState = "paused";
    pauseSvg.style.display = "none";
    playSvg.style.display = "block";
  });
}

// Toggle animationPlayState and respective play/pause SVGs if play/pause button is clicked. Store last choice in local storage
toggleAnimations = () => {
  planetsAnimation.forEach((element) => {
    if (!element.style.animationPlayState) {
      element.style.animationPlayState = "paused";
      localStorage.setItem("animation", "off");
      pauseSvg.style.display = "none";
      playSvg.style.display = "block";
      playSvg.focus();
    } else if (element.style.animationPlayState) {
      element.style.animationPlayState = "";
      localStorage.setItem("animation", "on");
      playSvg.style.display = "none";
      pauseSvg.style.display = "block";
      pauseSvg.focus();
    }
  });
};

// Add event listers to both pause and play SVGs
playPauseButton.forEach((element) => {
  element.addEventListener("click", toggleAnimations);
  element.addEventListener("keydown", function (event) {
    if (
      event.keyCode === 13 ||
      event.keyCode === 32 ||
      event.code === "Enter" ||
      event.code === "Space"
    ) {
      event.preventDefault();
      toggleAnimations();
    }
});

});