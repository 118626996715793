const sections = document.querySelectorAll("section");
const navLi = document.querySelectorAll("li");

window.addEventListener("scroll", () => {
  let current = "";
  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    if (
      window.innerHeight + Math.round(window.scrollY) >=
      document.body.offsetHeight
    ) {
      current = section.getAttribute("id");
    }
    if (scrollY >= sectionTop - 300) {
      current = section.getAttribute("id");
    }
  });

  navLi.forEach((li) => {
    li.classList.remove("nav-active");
    if (li.classList.contains(current)) {
      li.classList.add("nav-active");
    }
  });
});

navLi.forEach((li, index) => {
  const anchor = li.querySelector("a");
  li.addEventListener("keydown", (event) => {
    if (event.code === "Enter" || event.code === "Space") {
      // Prevent default behavior (scrolling)
      event.preventDefault();

      // Scroll to the referenced anchor link
      const targetId = anchor.getAttribute("href").slice(1);
      const targetElement = document.getElementById(targetId);
      targetElement.scrollIntoView({ behavior: "smooth" });

      // Set focus on the anchor link
      anchor.focus();
    } else if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      const nextIndex = event.key === "ArrowDown" ? index + 1 : index - 1;
      const nextLi = navLi[nextIndex];
      if (nextLi) {
        nextLi.querySelector("a").focus();
      }
    }
  });
});
